import React, { forwardRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  NavLink as RouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  List,
  ListItemText,
  ListItem,
  Button,
  colors,
  Paper,
  Tooltip,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Profile } from '../index';
import {
  ExitToApp,
  AccountCircle,
  // Settings,
  AccountBox,
} from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { loadCookie } from 'common/cookies';

import { deleteCookie } from 'common/cookies';

const SideBarButton = withStyles({
  root: {
    minWidth: 0,
  },
})(Button);

const NavItemLabel = withStyles({
  tooltip: {
    height: 'auto',
    width: 'auto',
    padding: '0.3rem',
    display: 'flex',
    fontWeight: '500',
    fontSize: '14px',
    alignItems: 'center',
    backgroundColor: '#EC407A',
  },
  arrow: {
    color: '#EC407A',
  },
})(Tooltip);

const AccountdMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: 'auto',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.white,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.white,
    fontWeight: theme.typography.fontWeightMedium,
  },
  active: {
    background: '#7D89CD',
    borderRadius: '4px',
    fontWeight: theme.typography.fontWeightBold,
  },
  profile: {
    alignItems: 'flex-end',
    flex: '1',
  },
  topList: {
    paddingTop: '5px',
  },
  logo: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

function Logo(props) {
  let [src, width, height] = ['/images/logos/logo.svg', '30', '30'];

  if (props.isCollapse) {
    [src, width, height] = ['/images/logos/logo--white.svg', '141', '36'];
  }

  return (
    <img
      alt="logo"
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
      src={src}
      width={width}
      height={height}
    />
  );
}

const CustomRouterLinkRef = (props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
);
const CustomRouterLink = forwardRef(CustomRouterLinkRef);

const SidebarNav = props => {
  const { collapse, pages, className, ...rest } = props;
  const { user } = loadCookie();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.topList} disableGutters>
        <Logo isCollapse={collapse} />
      </ListItem>

      <Divider className={classes.divider} />

      {pages.map(page => {
        return collapse ? (
          <ListItem
            className={classes.item}
            disableGutters
            key={page.title}
            button>
            <ListItem
              button
              key={page.title}
              onClick={() => history.push(page.href)}
              className={
                location.pathname === page.href ? classes.active : null
              }>
              <ListItemIcon
                style={{ minWidth: '45px' }}
                className={classes.icon}>
                {page.icon}
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.text }}
                primary={page.title}
              />
            </ListItem>
          </ListItem>
        ) : (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <SideBarButton
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}>
              <NavItemLabel arrow title={page.title} placement="right-start">
                <div className={classes.icon}>{page.icon}</div>
              </NavItemLabel>
            </SideBarButton>
          </ListItem>
        );
      })}

      <ListItem className={classes.profile} disableGutters>
        {collapse ? (
          <ListItem
            className={classes.item}
            onClick={handleClick}
            aria-haspopup="true"
            button
            style={{ padding: '10px 8px' }}>
            <ListItemIcon style={{ minWidth: '45px' }} className={classes.icon}>
              {' '}
              <AccountCircle />{' '}
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.text }}
              primary="Account"
            />
          </ListItem>
        ) : (
          <SideBarButton
            className={classes.button}
            onClick={handleClick}
            aria-haspopup="true">
            <NavItemLabel arrow title="Account" placement="right-start">
              <div className={classes.icon}>
                <AccountCircle />
              </div>
            </NavItemLabel>
          </SideBarButton>
        )}
        <AccountdMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <Paper style={{ padding: '0.4rem' }}>
            <Profile />
          </Paper>
          <StyledMenuItem
            onClick={event => {
              event.preventDefault();
              if (user?.isFundUser) {
                history.push(`/profile/user/funder/${user?.id}`);
              } else {
                history.push('/profile');
              }
            }}>
            <ListItemIcon>
              <AccountBox fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </StyledMenuItem>
          {/* <StyledMenuItem */}
          {/*   onClick={event => { */}
          {/*     event.preventDefault(); */}
          {/*     if (user?.isFundUser) { */}
          {/*       history.push('/settings'); */}
          {/*     } */}
          {/*   }}> */}
          {/*   <ListItemIcon> */}
          {/*     <Settings fontSize="small" /> */}
          {/*   </ListItemIcon> */}
          {/*   <ListItemText primary="Account Settings" /> */}
          {/* </StyledMenuItem> */}
          <StyledMenuItem
            id="logout-button"
            onClick={event => {
              event.preventDefault();
              deleteCookie();
              history.push('/sign-in');
            }}>
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </StyledMenuItem>
        </AccountdMenu>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  collapse: PropTypes.bool,
  pages: PropTypes.array.isRequired,
};

Logo.propTypes = {
  isCollapse: PropTypes.bool,
};

export default SidebarNav;
